import type { Performer } from '@/ontology/performer';
import type { User } from '@/ontology/user';
import { delEte, get, post } from '../fetch-extension';
import { transformPerformers, type Performers } from '../performer/utils';
import type { Response, Paged } from '../response';

// Retrieve a list of the subscriptions from performers of a consumer
export async function getSubscriptions(userId: number, query: { offset: number; limit: number }): Promise<Response<Paged<Performer>>> {
    const response = await get<Performers>(`client/client_accounts/${userId}/subscriptions`, { query });
    return transformPerformers(response, query.offset);
}

// Adds subscription to a performer
export async function addSubscriptions(userId: number, performerId: number) {
    return post<User>(`client/client_accounts/${userId}/subscriptions/${performerId}`);
}

// Removes a performer from subscriptions list
export async function removeSubscriptions(userId: number, performerId: number) {
    return delEte<User>(`client/client_accounts/${userId}/subscriptions/${performerId}`);
}

// Retrieve a list of the subscription options
export async function getSubscriptionsOptions() {
    return get<any>('client/client_accounts/subscriptions');
}
