import notifications from '@/socket';
import { defineStore } from 'pinia';
import type { Message } from '@/ontology/messaging';
import { encodeHTML, match, sanitize, seconds } from '@/utils';

import { sendMsg } from '@/api/messaging';
import { useCamStore } from './cam';
import { watch } from 'vue';
import { useUserStore } from './user';
import { content } from '@/api/messaging/utils';

interface State {
    messages: Message[];
    performerId: number;
    otherTyping: any;
    selfTyped: number;
    disappearingMessages: boolean;
    now:number;
    filterInterval: any;
}

interface ChatMessage {
    senderType: 'ROLE_PERFORMER' | 'ROLE_CLIENT' | 'ROLE_SYSTEM' | 'ROLE_TOY';
    content: string;
    contentTranslate?: string;
}

interface TypingReceivedMessage {
    recentTyping: boolean;
    inBuffer: boolean;
}

const defaultMessage: Message = {
    billingStatus: 'FREE',
    content: '  ...   ',
    date: new Date(),
    folder: 'DRAFT',
    id: Date.now(),
    subject: '',
    performerId: -1,
    readStatus: 'OLD',
    sentBy: 'PERFORMER',
    translate: false,
    type: 'chat'
};

export const useChatStore = defineStore('Chat', {
    state: (): State => ({
        messages: [],
        performerId: -1,
        otherTyping: false,
        selfTyped: 0,
        disappearingMessages: true,
        now: 0,
        filterInterval: undefined
    }),
    actions: {
        initialize() {
            notifications.subscribe('msg', this.handleMessage);
            notifications.subscribe('msgt', this.handleMessage);
            notifications.subscribe('typing_received', this.otherTyping);
            watch( ()=> useCamStore().status, this.camStatusChanged );
            
        },

        handleMessage({ senderType, content, contentTranslate }: ChatMessage) {
            let sentBy: 'CLIENT' | 'PERFORMER' | 'SYSTEM' | 'TOY' = 'SYSTEM';
            switch (senderType) {
                case 'ROLE_PERFORMER':
                    sentBy = 'PERFORMER';
                    break;
                case 'ROLE_CLIENT':
                    sentBy = 'CLIENT';
                    break;
                case 'ROLE_TOY':
                    sentBy = 'TOY';
                    break;
            }
            const msg: Message = {
                ...defaultMessage,
                ...{
                    date: new Date(),
                    id: Date.now(),
                    folder: 'INBOX',
                    performerId: this.performerId,
                    content: sanitize( content.replace(/\+/g,' ') ),
                    contentTranslate: contentTranslate ? sanitize(contentTranslate.replace(/\+/g,' ')) : undefined,
                    sentBy,
                    translate: !!contentTranslate
                }
            };

            //42["receivedEvent","{\"event\":\"msgt\",\"receiverType\":\"ROLE_CLIENT\",\"content\":\"%7B%22senderId%22%3A158%2C%22senderType%22%3A%22ROLE_PERFORMER%22%2C%22language%22%3A%22nl%22%2C%22languageTranslate%22%3Anull%2C%22content%22%3A%22asdf%22%2C%22contentTranslate%22%3Anull%7D\",\"receiverId\":\"56121261\",\"senderType\":\"ROLE_PERFORMER\"}"]

            const first = this.messages[0];
            //check if the performer is typing..
            if (first && first.folder == 'DRAFT' && first.sentBy == 'PERFORMER') {
                //replace the 'typing message with the received one if sent by performer
                if (msg.sentBy == 'PERFORMER') {
                    this.messages[0] = msg;
                } else {
                    //otherwise, insert it after
                    this.messages.splice(1, 0, msg);
                }
            } else {
                this.messages.unshift(msg);
            }

            if (msg.sentBy == 'PERFORMER') {
                clearTimeout(this.otherTyping);
                this.otherTyping = false;
            }

            notifications.sendLocalEvent("new_message_in_conversation", { performer: this.performerId });
        },

        systemMessage( content: string ){
            this.handleMessage( { senderType:'ROLE_SYSTEM', content })
        }, 

        otherTyping({ recentTyping, inBuffer }: TypingReceivedMessage) {
            if (!this.otherTyping) {
                const message: Message = {
                    ...defaultMessage,
                    ...{
                        id: Date.now(),
                        performerId: this.performerId
                    }
                };
                this.messages.unshift(message);
            } else {
                clearTimeout(this.otherTyping);
            }
            this.otherTyping = setTimeout(this.typingStopped, 3000);
        },

        typingStopped() {
            clearTimeout(this.otherTyping);
            this.otherTyping = false;
            if (this.messages[0] && this.messages[0].folder == 'DRAFT') {
                this.messages.splice(0, 1);
            }
        },

        selfTyping(){
            if ( ( Date.now() - this.selfTyped ) < seconds(2) ){
                return;
            }

            notifications.sendCustomEvent('event', {
                event: 'typing_received',
                receiverId: this.performerId,
                receiverType: 'ROLE_PERFORMER',
                content: encodeURIComponent(JSON.stringify({
                    recentTyping:true, 
                    inBuffer:true, 
                    senderType: "ROLE_CLIENT",
                    senderId: useUserStore().account!.id
                }))
            });
            this.selfTyped = Date.now();
        },

        toggleDisappearingMessages(){
            this.disappearingMessages = !this.disappearingMessages;
        },

        camStatusChanged(newValue: string, oldValue:string ) {
            if (newValue == 'ended'){
                this.resetMessages();
                clearInterval( this.filterInterval );
            }

            if (newValue == 'active' && ['cam', 'videocall'].includes(useCamStore().type)){
                this.systemMessage('je kunt nu chatten...');
                this.now = Date.now();
                this.filterInterval = setInterval( ()=> this.now += seconds(1), seconds(1) )
            }
            
        },
        resetMessages() {
            this.messages = [];
        },

        async send(content: string, performer: number) {
            content = sanitize(content);
            this.performerId = performer;
            if (!this.performerId) {
                return;
            }

            const { error } = await  sendMsg({ msg: content, receiver: this.performerId });
            if (error) {
                return error;
            }

            this.handleMessage({ senderType: 'ROLE_CLIENT', content });
        }
    },
    getters:{
        filtered():Message[] {
            return this.messages;
        }
        //     const timePerLetter = 200; 
            
        //     if (!this.disappearingMessages){
                
        //     }
    
        //     //let's find the index of the last message that should remain
        //     let k = this.messages.length-1;
        //     for(k; k >=0; k--){
        //         const message = this.messages[k];
        //         if (message.folder == 'DRAFT'){
        //             break;
        //         }
        //         const duration = Math.max( content(message).length * timePerLetter, 5000 );
        //         if (this.messages[k].date.getTime()+duration > this.now){
        //             break;
        //         }
        //     }   
    
        //     if (k ==-1){
        //         return [];
        //     }
    
        //     return this.messages.slice(0, k+1);
        // }
    }
});
