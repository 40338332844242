export const DEFAULT_LIMIT = 42;
export const DEFAULT_OFFSET = 0;

export interface UrlParams {
    limit: number;
    offset?: number;
    language?: string;
}

export interface PerformerListParams extends UrlParams {
    withPhotos?: number;
}

export interface PerformerCategoryListParams extends PerformerListParams {
    category: string;
}

export interface PerfomerSearchListParams extends PerformerListParams {
    search: string;
}
