import type { SessionType, Stream } from '@/ontology/stream';
import { delEte, get, post } from '../fetch-extension';
import type { Response } from '../response';
import { code } from './utils';
import { getQueryString } from '@/utils';

interface SessionBody {
    performerId: number;
    clientId: number;
    name: string;
    ivrCode?: string;
    payment?: string;
    type?: 'PEEK' | 'VIDEO';
}

export interface ToyResponse {
    rules?: {
        hasRules: boolean;
        hasSpecial: boolean;
        rules?: [];
        specialRules?: [];
    };
}

export async function camRequest(body: SessionBody) {
    return sessionRequest('cam', body);
}

export async function peekRequest(body: SessionBody) {
    return sessionRequest('peek', body);
}

export async function sessionRequest(type: SessionType, body: SessionBody): Promise<Response<{ ok: 'wait' }>> {
    body.payment = body.ivrCode ? 'IVR' : 'CREDITS';
    body.type = type == 'peek' ? 'PEEK' : 'VIDEO';
    
    const apiType = type == 'cam' ? 'chat' : type;

    const { error, result } = await post<{ ok?: 'wait'; error?: string }>(`session/request/${apiType}`, { body });
    if (error) {
        return { error };
    }

    if (!result) {
        throw new Error('impossible');
    }

    if (result.error) {
        return {
            error: { message: result.error, code: code(result.error) }
        };
    }

    return {
        result: { ok: 'wait' }
    };
}

export async function deleteRequest(performerId:number){
    return await delEte(`session/videochat_request/${performerId}`);
}

interface VideoBody {
    advert: number;
    clientId: number;
    name?: string;
    ivrCode?: string;
    type?: 'VOYEURPEEK' | 'VOYEUR';
    performerId?: number
}

const sample = {
    playStream: '83f157fc7522f4fe57ec38eea0641871',
    playToken: '1de6252fa7e4e5e93daf1efbc9d8be5a',
    playStreamTransportType: 'JANUS',
    playWowza: 'webrtc02.thuis.nl',
    streamTransportType: 'JANUS',
    wowza: 'webrtc02.thuis.nl',
    id: '1de6252fa7e4e5e93daf1efbc9d8be5a',
    streamJanusBackProc: '100'
};

//trimmed down response with only the props we actually use
interface VideoResponse {
    id: string;
    playStream: string;
    playToken: string;
    playWowza: 'webrtc02.thuis.nl';
    publishStream?: string;
    publishToken?: string;
    publishWowza?: 'webrtc02.thuis.nl';
}

export async function initiateVideo(body: VideoBody): Promise<Response<VideoResponse>> {
    //session/performer_account/performer_number/6422/initiate_videochat
    const { name: chatroomName, advert, ivrCode, clientId, type, performerId } = body;

    const { error, result } = await post<VideoResponse | { error: string }>(`session/performer_account/performer_number/${advert}/initiate_videochat`, {
        body: {
            chatroomName,
            clientId: ivrCode ? undefined : clientId,
            ivrCode,
            type,
            performerId
        }
    });

    if (error) {
        console.log("error initiating");
        return { 
            error: {
                message: error.message, code: code( error.message )
            }
        };
    }

    if (!result) {
        throw new Error('impossible');
    }

    if ('error' in result) {
        console.log("error initiating with a 200");
        return {
            error: { code: code(result.error), message: result.error }
        };
    }

    return {
        result
    };
}

export async function cancel(clientId: number, performerId: number) {
    return post('/session/cancel', { body: { clientId, performerId } });
}

export async function performerTimedOut(clientId: number, performerId: number) {
    return post('/session/timeout/performer', {
        body: { clientId, performerId }
    });
}

export async function clientSeen(app: 'VIDEO' | 'VOYEUR' = 'VIDEO') {
    return get('/session/client_seen', { query: { app } });
}

export async function endSession(body?:{clientId: number, performerId?: number, type: "VOYEUR" | "VOYEURPEEK" | "VOYEURCLIENT"}) {
    //session/end
    const { error, result } = await post<{ ok: 'ended' } | { error: string }>('/session/end', { body: body || {} });

    if (error) {
        return { error };
    }

    if (!result) {
        throw new Error('impossible');
    }

    if ('error' in result) {
        return {
            error: { code: 400, message: result.error }
        };
    }

    return {
        result
    };
}

export async function initiateTeaser(clientId:number, ivrCode?:string){
    const body = ivrCode ? { ivrCode, payment: "IVR" } : { clientId };
    const { error, result } = await post<["ok"] | {error:string}>('/session/initiate_voyeurclient', { body } );
    if (error){
        return { error };
    }

    if (!result){
        throw new Error('impossible');
    }
    if ('error' in result){
        return {
            error: { code: 400, message: result.error }
        }
    }

    return { result };
}

export async function switchMainTeaser(toPerformer: number){
    const body = {};
    //TODO: when this api endpoint is live, use it instead of the old one
    const { error, result } = await post<{ok:"switched"} | {error:string}>(`/session/performer_account/${toPerformer}/voyeur_new`, { body })
    //const { error, result } = await post<{ok:"switched"} | {error:string}>(`/session/performer_account/${toPerformer}/voyeur`, { body })
    if (error){
        return { error };
    }   
    if (!result) throw new Error('impossible');

    if ('error' in result){
        return {
            error: { code: 400, message: result.error }
        }
    }

    return { result };
}

export async function startCall(performerId: number, ivrCode: string){
    return  await post(`/session/start_audio/${performerId}`, {
        body: { ivrCode }
    });
}

export async function endCall(ivrCode:string){
    return await post(`/session/stop_audio`, {
        body: { ivrCode }
    });
}

export function createPhoneReservation(advertNumber:number){ 
    return get<{DNIS: string}>(`/session/make_reservation/${advertNumber}/PHONE?_format=json`) 
};


export async function getToyInfo(advertNumber: number) {
    return get<ToyResponse>(`/session/performer_account/performer_number/${advertNumber}/toy_info`);
}

export async function initiateToyQuantity(clientId: number, advert: number, qnt: 1 | 2 | 4) {
    const query = {
        clientId,
        qnt
    };

    return initiateToy(advert, query);
}

export async function initiateToyRule(clientId: number, advert: number, quantity: number, rule: number, special: boolean, itemId?: number) {
    const query = {
        clientId,
        qnt: quantity,
        rule,
        special,
        itemId
    };
    return initiateToy(advert, query);
}

async function initiateToy(advert: number, query: {}) {
    const body = {};
    const queryStr = getQueryString(query);
    const { error, result } = await post<['OK'] | { error: string }>(`/session/performer_account/performer_number/${advert}/initiate_toy?${queryStr}`, { query, body });
    if (error) {
        return { error };
    }
    if (!result) {
        throw new Error('impossible');
    }

    if ('error' in result) {
        return {
            error: { code: code(result.error), message: result.error }
        };
    }

    return { result };
}