import { defineStore } from 'pinia';
import type { Info } from '@/ontology/info';
import type { Language } from '@/ontology/i18n';
import { getInfo } from '@/api/client/client_accounts.info';
import localize from '@/api/localize';
import config from '@/config';
import loc from '@/api/loc';
import i18n from '@/translations';

interface State extends Info {
    loading: boolean;
    //the real location (country) of the user
    location: string;
}

export const useLocalizationStore = defineStore('localization', {
    //I defined the State as Partial Info so I didn't have to default all the Info properties
    state: (): Partial<State> => ({
        loading: false,
        categories: [],
        ivr1: undefined,
        ivr2: undefined,
        countries: [],
        country: undefined,
        credits_status: undefined,
        credits_per_email: undefined,
        credits_per_minute: undefined,
        credits_per_sms: undefined,
        credits_per_toy_5s: undefined,
        language: undefined,
        languages: [],
        mail_cpm: undefined,
        mail_enabled: undefined,
        marketing: undefined,
        tags: []
    }),

    actions: {
        async initialize(language: Language) {
            if (this.loading) {
                return;
            }

            await this.setLanguage(language);
            await this.setLocation();
        },
        async setLanguage(language: Language) {
            if (this.loading) {
                return;
            }

            if (!i18n.global.availableLocales.includes(language)) {
                throw new Error(`${language} not in ${i18n.global.availableLocales}`);
            }
            (i18n.global.locale as any).value  = language;

            this.loading = true;
            //the result is ignored! Basically, it's just a way to
            //tell the server the locale we'd like to use, to prepare for the upcoming info call
            const { error: e10n, result: location } = await localize(language);
            if (e10n) {
                //TODO: error handling!
                return;
            }

            //TODO: we could do these requests simultaneously
            const { error: eInfo, result: info } = await getInfo();
            if (eInfo) {
                //TODO: how do we do error handling in this context?
                return;
            }

            //TODO: add sorting to api for categories
            let number = 2;
            info?.categories.forEach(function (cat) {
                cat.order = number === 1 ? 2 : 1;
                number = number === 1 ? 2 : 1;
            });

            this.$patch({
                ...info,
                ...{
                    loading: false,
                    language: location!.language
                }
            });
        },
        async setLocation() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            const { error: eloc, result: location } = await loc();
            if (eloc) {
                //TODO: error handling bitch
                return;
            }

            this.$patch({
                loading: false,
                location: (location!.country_code || "").toLowerCase()
            });
        }
    },

    getters: {
        isCreditCardSafe: state => {
            return state.location ? config.AllowedCreditcardCountries.includes(state.location) : true;
        },
        numbers: state => {
            const result: {
                country: string;
                number: string;
                tariff: number;
            }[] = [];

            const { ivr1, ivr2 } = state;
            const activeCampaign: any = state.marketing?.current;
            const marketing: any = state.marketing;
            
            if (ivr1 && ivr1.phone_enabled === 1) {
                result.push({
                    country: 'nl',
                    number: !marketing.current ? ivr1.phone_number : marketing[activeCampaign].phone_number,
                    tariff: !marketing.current ? ivr1.phone_cpm : marketing[activeCampaign].phone_cpm
                });
            }
            if (ivr2 && ivr2.phone_enabled === 1) {
                result.push({
                    country: 'be',
                    number: !marketing.current ? ivr2.phone_number : marketing[activeCampaign].phone_number,
                    tariff: !marketing.current ? ivr2.phone_cpm : marketing[activeCampaign].phone_cpm
                });
            }
            return result;
        }
    }
});
