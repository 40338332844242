import type { Status as PerformerStatus, Service, Performer, ServiceStatus } from '@/ontology/performer';
import { fromUnixTime } from 'date-fns';
import type { Paged, Response } from '../response';
import type { Performer as PerformerFromApi } from './model';
import { pickOne, randomBetween } from '@/utils';
import subWeeks from 'date-fns/subWeeks';

export interface Filter {
    offset: number;
    limit: number;
    search?: string;
    category?: string;
    //Performer to whom to relate other performers.
    //relatedTo refers to an AdvertId, not an ID!
    relatedTo?: number;
    withPhotos?: 0 | 1;
}

export interface Performers {
    total: number;
    performerAccounts: PerformerFromApi[];
}

export function transformPerformers(
    response: Response<{
        total: number;
        performerAccounts: PerformerFromApi[];
    }>,
    offset: number
): Response<Paged<Performer>> {
    if (response.error) {
        return { error: response.error };
    }

    const { total, performerAccounts } = response.result!;

    //api response for 'no results' is an empty array ¯\_(ツ)_/¯. To detect that, I check if total has a value.
    //if no, transform to an empty array
    return {
        result: {
            items: total ? performerAccounts.map(transformPerformer) : [],
            offset: offset ?? 0,
            total
        }
    };
}

export function transformPerformer(p: PerformerFromApi): Performer {
    const sharedProperties = ['age', 'avatar', 'country', 'cupSize', 'description', 'eyeColor', 'height', 'id', 'isSubscribed', 'isVoyeur', 'language', 'location', 'mediaId', 'nickname', 'status', 'statusUpdated', 'registerDate', 'safeDescription', 'socketToken', 'username', 'weight', 'photos', 'media'];

    if (!p.advert_numbers) {
        console.log(p);
    }
    const same = filter(p, sharedProperties);
    const different: Partial<Performer> = {
        advertNumber: p.advert_numbers[0].advertNumber,
        avatarMedia: p.avatar_media,
        languages: (p.performerLanguages || 'nl;').split(';').slice(0, -1),
        status: p.performerStatus,
        statusUpdated: p.performerStatusUpdated,
        isFavorite: p.isFavourite,
        services: {
            ...defaultServices(),
            ...Object.entries(p.performer_services).reduce((sofar: any, [service, intention]) => {
                sofar[service] = {
                    status: serviceStatus(p.performerStatus, service as Service, intention),
                    intention
                };
                return sofar;
            }, {})
        },
        role: 'ROLE_PERFORMER',
        safeAvatar: p.safe_avatar
    };

    return {
        ...same,
        ...different
    };
}

export function statuses(services: { [name: string]: { intention: boolean; status: ServiceStatus } }): { [name: string]: ServiceStatus } {
    const result: any = {};
    for (let service in services) {
        result[service] = services[service].status;
    }
    return result;
}

// Wat is de actuele status van een dienst, afhankelijk van de intentie en de status van de performer
// als de intentie niet in de matrix zit, dan is de status van de dienst gelijk aan de intentie.
// Als er 'intentie' is voor een bepaalde dienst, en het resultaat zit niet in de matrix, dan is de status 'available'
export function serviceStatus(performer: PerformerStatus, service: Service, intention: boolean): ServiceStatus {
    //console.log( `wat is de status voor een ${performer} performer en dienst ${service} als de intentie er ${ intention ? 'wel' : 'niet'} is `)
    if (!intention) {
        return 'off';
    }

    // prettier-ignore
    const matrix:any= {
        OFFLINE:  { cam:"off",       videocall: "off",       peek:"off",       voyeur: "off",       toy: "off",       phone: "available" },
        AVAILABLE:{ cam:"available", videocall: "available", peek:"on",        voyeur: "available", toy: "on",        phone: "available" },
        REQUEST:  { cam:"on",        videocall: "on",        peek:"on",        voyeur: "available", toy: "on",        phone: "on" },
        ON_CALL:  { cam:"on",        videocall: "on",        peek:"on",        voyeur: "available", toy: "on",        phone: "on" }, 
        BUSY:     { cam:"on",        videocall: "on",        peek:"available", voyeur: "available", toy: "available", phone: "on" } 
    }

    return matrix[performer][service] || 'available';
}

export function toyStatus( performer:Performer ): "off" | "on" | "available" {
    const { toy, cam } = performer.services;
    return cam.intention ? toy.status : "off";     
}



export function defaultServices() {
    const services: Service[] = ['callconfirm', 'cam', 'chat', 'email', 'peek', 'phone', 'sms', 'toy', 'videocall', 'voicemail', 'voyeur', 'showsnapshot'];

    return services.reduce((sofar: any, current) => {
        sofar[current] = {
            intention: false,
            status: 'off'
        };
        return sofar;
    }, {});
}

//returns a new object containing a (shallow) copy of the properties listed in 'props'
function filter(anObject: any, props: string[]) {
    const result: any = {};
    for (let prop of props) {
        if (prop in anObject) {
            result[prop] = anObject[prop];
        }
    }
    return result;
}

export function getPerformerStatus(performer: Performer): 'offline' | 'available' | 'busy' | 'teaser' {
    switch (performer.status) {
        case 'AVAILABLE':
            if (performer.services.phone.status === 'off' && performer.services.cam.status === 'off' && performer.services.voyeur.status === 'off') {
                return 'offline';
            }

            return 'available';

        case 'OFFLINE':
            if (performer.services.phone.status === 'available') {
                return 'available';
            }

            return 'offline';

        case 'BUSY':
            //TODO: maybe revert once teasr is enabled
            // if(performer.services.voyeur.status !== 'off'){
            //     return 'teaser';
            // }

            return 'busy';

        case 'REQUEST':

        case 'ON_CALL':
            return 'busy';

        default:
            throw new Error('Given status does not exist!');
    }
}

//what is the most relevant service the performer offers that can be consumed?
//TODO: renamve this function? it 'return the most relevant realtime service this performer is available for"
//TODO: turn on teasable
export function camStatus(performer: Performer | undefined): 'unknown' | 'offline' | 'camable' | 'peekable' | 'teasable' | 'callable' | 'busy' {
    if (!performer) {
        return 'unknown';
    }
    if (performer.services['cam'].status == 'available') {
        return 'camable';
    }
    if (performer.services['peek'].status == 'available') {
        return 'peekable';
    }

    //I'll call a performer peekable if her cam is on (she's in a session) and voyeur is available
    //TODO: revert teasable
    // if (['BUSY', 'REQUEST'].includes(performer.status) && performer.services['voyeur'].status == 'available') {
    //     return 'teasable';
    // }

    if (performer.services['phone'].status == 'available') {
        return 'callable';
    }

    if (performer.services['voyeur'].status == 'available') {
        //return 'teasable';
        return 'offline';
    }

    if (getPerformerStatus(performer) == 'busy') {
        return 'busy';
    }

    if (getPerformerStatus(performer) == 'offline') {
        return 'offline';
    }

    throw new Error(`camStatus of ${performer.status} with ${JSON.stringify(performer.services)} is impossible`);
}


export function performerStatusColor(performer: Performer | undefined){
    if (!performer) {
        return 'offline';
    }
    const status = camStatus(performer);
    if(status === 'camable' || status === 'callable'){
        return 'available';
    //TODO: re-set to peek when teaser is actually implemented
    } else if (status === 'peekable'){ //|| status === 'teasable'){
        return 'peek';
    } else if (status === 'busy'){
        return 'busy';
    } else {
        return 'offline';
    }
}

//how long ago did we last see the performer? in miliseconds
export function lastSeenAgo(p:Performer):number{
    const seen = p.statusUpdated * 1000;
    const now = Date.now();
    return now - seen;
}

export function randomPerformer(id:number): Performer{
    const status = pickOne(['AVAILABLE', 'OFFLINE', 'BUSY', 'ON_CALL', 'REQUEST']);
    const services = ['cam', 'email', 'peek', 'phone','toy','voyeur'].reduce( 
        (sofar, current)=>{
            sofar[current] = serviceStatus( status, current as Service, pickOne([true, false]) );
            return sofar;
        }, {} as any )
    const name = pickOne(["Vanessa", "Lotte", "Kimara", "Sebriena", "Talia"]);
    const color = pickOne( ['available', 'peek', 'busy', 'offline'] )

    return {
        advertNumber: randomBetween(1000, 9999),
        age: randomBetween(21, 80),
        avatar: undefined,
        avatarMedia: undefined,
        country: pickOne(['nl', 'be', 'su', 'pl']),
        cupSize: pickOne(['xsmall', 'small', 'medium', 'large', 'xlarge']),
        description: 'lorum ipsum dolor sit, amet',
        eyeColor: color,
        height: `${randomBetween(150, 200)}`,
        id,
        isFavorite: pickOne([true, false]),
        isSubscribed: pickOne([true, false]),
        isVoyeur: false,
        language: 'nl',
        location: pickOne(["Drenthe", "Groningen", "Friesland", "Overijssel", "Flevoland", "Brabant", "Limburg", "Zeeland"]),
        mediaId: 4,
        nickname: name,
        languages: ['nl', 'en', 'de'],
        status,
        statusUpdated: randomBetween( subWeeks(new Date(), 3).getTime(), Date.now())  / 1000,
        //the actual status of a service depends on the status of the performer,
        //and the intention of the performer to offer that service. If intention is true,
        //the performer wants to deliver that status. See https://sensemakersbeheer.sharepoint.com/sites/Client-sidedev2022/SitePages/Service.aspx
        services,
        registerDate: randomBetween( new Date(2014, 10, 1 ).getTime(), Date.now() ) / 1000,
        role: 'ROLE_PERFORMER',
        safeDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        safeAvatar: undefined,
        socketToken: randomBetween(1000, 9999).toString(16),
        username: name,
        weight: `${randomBetween(45, 100)} kilo`,
        photos: [],
        media: []
    }
}

//Transforms the location from the api in the form <country>/<province>/<city>
//to the specific thing
export function displayLocation(source:string = ''){
    return ( source.split('/').reverse().find( item => item.trim() ) || 'Netherlands' ).trim();
}