const config = {
    BaseUrl: enved('BaseUrl'),
    FullApiUrl: enved('FullApiUrl'),
    SocketUrl: enved('SocketUrl'),
    Themename: enved('Themename'),
    ImageUrl: enved('ImageUrl'),
    SnapshotUrl: enved('SnapshotUrl'),
    //deprecated
    JsmpegUrl: enved('JsmpegUrl'),
    //deprecated
    JanusmpegUrl: enved('JanusmpegUrl'),
    StorageKey: enved('StorageKey'),
    Country: enved('Country'),
    VodServer: enved('VodServer'),
    //Deprecated
    H5FlashSwf: enved('H5FlashSwf'),
    FreeRegister: enved('FreeRegister') === 'true',
    GridCache: parseFloat(enved('GridCache')),
    //Deprecated
    Banner: enved('Banner') === 'true',
    AllowedCreditcardCountries: enved('AllowedCreditcardCountries').split(','),
    DefaultLanguage: enved('DefaultLanguage'),
    GoogleTagCode: enved('GoogleTagCode'),
    AgeCheck: enved('AgeCheck') === 'true',
    Hotjar: enved('Hotjar') === 'true',
    Services: enved('Services').split(','),
    Version: enved('Version')
};

export default config;

function enved(key: string): string {
    return import.meta.env[`VITE_${key}`] || '';
}


if(window.loadTagManager){
    window.loadTagManager(window, document, 'script', 'dataLayer', config.GoogleTagCode);
}