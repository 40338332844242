<script setup lang="ts">
    import { RouterView } from 'vue-router';
    import BottomMenu from './components/BottomMenu.vue';
    import Morpheus from './views/morpheus.vue';
    import { useRoute } from 'vue-router';
    import TopPopin from './components/TopPopin.vue';
    import { onMounted } from 'vue';
    import { useDeviceStore } from '@/stores/device';
    
    const route = useRoute();

    onMounted( ()=> useDeviceStore().registerServiceWorker() );
</script>

<template>
    <RouterView :key="route.name as string" />
    <TopPopin></TopPopin>
    <BottomMenu />
    <Morpheus />
</template>

<style lang="scss" scoped>
</style>