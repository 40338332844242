import type { LocationQueryValue } from 'vue-router';
import sanitizeHtml from 'sanitize-html';
import { useUserStore } from '@/stores/user';
import placeholder from '@/assets/images/placeholderprofile.jpg';
import config from '@/config';
import type { Performer } from './ontology/performer';
import platform from "platform";

export function getParameterByName(name: string, url?: string) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
        results = regex.exec(url);
    if (!results) return '';
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getConfirmation(){
    const [_, confirm, userId, token] = window.location.pathname.split("/");
    if (!(confirm == 'confirm' && userId && token) ){
        return undefined;
    }

    return { userId: parseInt(userId), token };
}

export function asInt(routeParam: string[] | string | LocationQueryValue | LocationQueryValue[]): number {
    let toParse: string;
    if (Array.isArray(routeParam)) {
        toParse = routeParam[0] as string;
    } else {
        toParse = routeParam as string;
    }
    return parseInt(toParse);
}

export function samish(one: number, two: number): boolean {
    return Math.trunc(one * 100) == Math.trunc(two * 100);
}

export function minutes(count: number) {
    return count * seconds(60);
}

export function seconds(count: number) {
    return count * 1000;
}

export function numbersOnly(evt: any){
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
    } else {
        return true;
    }
}

export function currency(amount?: number) {
    if (!amount) amount = 0;
    return new Intl.NumberFormat('nl-NL', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount);
}

export function scrollTop(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

export function startFromTop(){
    window.scrollTo({ top: 0, behavior: 'auto' });
}

export function getAvatar(performer: Performer, size: "small" | "medium" | "large" = "large") {
    const user = useUserStore();
    
    if (performer.lastShownPhoto){
        return `${config.ImageUrl}pimg/${performer.id}/${size}/${performer.lastShownPhoto.name}`;
    }

    if (user.safeMode && performer.safeAvatar?.safe_version) {
        return `${config.ImageUrl}pimg/${performer.id}/${size}/${performer.safeAvatar.name}`;
    }


    if (!user.safeMode && performer.avatar) {
        return `${config.ImageUrl}pimg/${performer.id}/${size}/${performer.avatar.name}`;
    }

    return placeholder;
}

export function getAvatarInbox(performer: any, avatar: string) {
    if (performer && performer.avatar) {
        return `${config.ImageUrl}pimg/${performer.performerId}/large/${avatar}`;
    } else {
        return placeholder;
    }
}

export function getAvatarPerformerList(performerId: number, avatar: string) {
    if (performerId && avatar) {
        return `${config.ImageUrl}pimg/${performerId}/large/${avatar}`;
    } else {
        return placeholder;
    }
}

export function getSliderImages(performer: Object, photo: Object | any, size?: string) {
    if (!photo){
        return placeholder;
    }
    
    const user = useUserStore();
    let sizes = size ? `${size}/` : window.innerWidth > 992 ? '' : 'large/';
    if (size == 'huge'){
        sizes = '';
    }

    if (user.safeMode && photo.safe_version) {
        return `${config.ImageUrl}pimg/${performer}/${sizes}${photo.name}`;
    }

    if (!user.safeMode) {
        return `${config.ImageUrl}pimg/${performer}/${sizes}${photo.name}`;
    }

    return placeholder;
}

export function getSliderVideos(video: string) {
    return `${config.FullApiUrl}${config.VodServer}/${video}`;
}

export function kebabToCamel(kebab: string) {
    return kebab
        .split('_')
        .map((part, index) => (index && part ? part[0].toUpperCase() + part.slice(1) : part))
        .join('');
}

// checks if 'pattern' is a subset of 'message'
// eg match( {id:3, text:"bla"}, {text:"bla"} ) => true
export function match(message: any, pattern: any): boolean {
    for (var prop in pattern) {
        if (!message) {
            return false;
        }
        if (!(prop in message)) {
            return false;
        }

        if (typeof pattern[prop] === 'object' && typeof message[prop] === 'object') {
            //recursive matching. No guards!
            if (!match(message[prop], pattern[prop])) {
                return false;
            }
        } else if (typeof pattern[prop] === 'function') {
            if (!pattern[prop](message[prop])) {
                return false;
            }
        } else if (pattern[prop] != message[prop]) {
            return false;
        }
    }

    return true;
}

export function among(options: any[]) {
    return (what: any) => options.includes(what);
}

//returns all items from from that aren't in what
export function subtract<T>(what:T[], from:T[], same?: (a:T,b:T)=>boolean):T[]{
    const result = [];
    for(var item of from){
        if (same){
            if ( !what.find( other => same(other, item)) ){
                result.push( item );
            }
        } else {
            if (what.indexOf(item) == -1){
                result.push( item );
            }
        }
    }

    return result;
}

//randomly picks an item from a list
export function pickOne( from:any[] ){
    if (!from.length){
        return undefined;
    }
    const ix = Math.floor( (Math.random() * from.length) );
    return from[ix];
}

export function encodeHTML(s: string) {
    return s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/"/g, '&quot;').replace(/:([^:]+):/g, "<i>$1</i>");
}

export function sleep(delay: number): Promise<void>{
    return new Promise( (resolve, _) => {
        setTimeout(resolve, delay);
    });
}

export function bigBlackPoster(){
    return new Promise<string>( (resolve, reject)=>{
        const canvas = document.createElement("canvas");
        canvas.width = 2000;
        canvas.height = 2000;

        canvas.toBlob(blob=>{
            if (!blob){
                reject("unblobbable")
            } else {
                const result  = URL.createObjectURL(blob);
                console.log( result );
                resolve( result )
            }
        });
    })
}

export function isTooApple(){
    //tooApple means the platform is too apple to start a chat (not peek) video unmuted
    //All safaris have trouble, and if you don't use Apple's browser, iOS is always too apple.
    //TODO: check ipad os?
    return platform.name == "Safari" || platform.os?.family =="iOS";
}

export function randomBetween(from: number, until:number):number{
    if (until <= from){
        throw `until (${until}) must be bigger than from (${from})`;
    }
    return Math.round( ( Math.random() * (until-from) ) ) + from;
}

export function isOdd( aNumber:number ){
    return aNumber % 2;
}

export function sanitize(text: string): string {
    return sanitizeHtml(text, {
        allowedTags: [],
        allowedAttributes: {},
        allowedIframeHostnames: []
    });
}

export function getQueryString(queryObject: any): string {
    return Object.keys(queryObject)
        .filter(k => queryObject && queryObject[k] !== undefined && queryObject[k] !== '')
        .map(k => (queryObject ? `${encodeURIComponent(k)}=${encodeURIComponent(queryObject[k])}` : ''))
        .join('&');
}

export function localStorageAvailable(){
    try{
        const s = window.localStorage;
        s.setItem("test", "true");
        s.removeItem("test");
        
        return true;

    } catch(e){
        
        return false;
        
    }
}

export async function isIncognitoGuess(){
    const { quota } = await navigator.storage.estimate();
    console.log( `quota is ${quota}` );
    return (quota || 0) < 9999999999
                          //2272245477
}