export enum Errors {
    BROKE = 493,
    WRONG_CODE,
    ALREADY_ACTIVE_SESSION,
    CLIENT_NOT_FOUND,
    PERFORMER_NOT_FOUND,
    YOU_ARE_BLOCKED,
    PERFORMER_NOT_AVAILABLE
}

//TODO no longer elephant path. 
export function code(message:string){
    const apiErrors: any = {
        'Performer ist noch nicht verfügbar': Errors.PERFORMER_NOT_AVAILABLE,
        'Performer is not available yet':  Errors.PERFORMER_NOT_AVAILABLE,
        'Performer nog niet beschikbaar': Errors.PERFORMER_NOT_AVAILABLE,

        'Die Dame hat dich blockiert': Errors.YOU_ARE_BLOCKED,
        'The performer has blocked you': Errors.YOU_ARE_BLOCKED,
        'De dame heeft je geblokkeerd': Errors.YOU_ARE_BLOCKED,

        'Performer nicht gefunden': Errors.PERFORMER_NOT_FOUND,
        'Performer not found': Errors.PERFORMER_NOT_FOUND,
        'Performer niet gevonden': Errors.PERFORMER_NOT_FOUND,

        'Client nicht gefunden': Errors.PERFORMER_NOT_FOUND,
        'Client not found': Errors.PERFORMER_NOT_FOUND,
        'Client niet gevonden': Errors.PERFORMER_NOT_FOUND,

        'Sie haben bereits eine aktive Sitzung': Errors.ALREADY_ACTIVE_SESSION,
        'You already have a active session': Errors.ALREADY_ACTIVE_SESSION,
        'U heeft al een actieve sessie': Errors.ALREADY_ACTIVE_SESSION,

        'Ungültiger Zugangscode!': Errors.WRONG_CODE,
        'Invalid payment code!': Errors.WRONG_CODE,
        'Ongeldige betaalcode!': Errors.WRONG_CODE,

        'Nicht genügend Guthaben': Errors.BROKE,
        'Insufficient credits': Errors.BROKE,
        'Onvoldoende credits': Errors.BROKE,

        'Failed to tax!': Errors.BROKE,

        '"Performer is not available for the requested service!"': Errors.PERFORMER_NOT_AVAILABLE,
        '"Performer is not available for videochat"': Errors.PERFORMER_NOT_AVAILABLE
    }

    return apiErrors[message] || 500;
}