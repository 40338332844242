import type { Message, Thread } from '@/ontology/messaging';
import { delEte, get, post } from '../fetch-extension';
import type { Paged, Response } from '../response';
import { transformThreadIn, transformMessageIn, transformMessageOut } from './utils';
import { seconds, sleep } from '@/utils';
import { code } from '../session/utils';

export async function getThreads(offset = 0, limit = 20, singleThread = false): Promise<Response<Paged<Thread>>> {
    const query:any = {
        offset, limit
    }
    if (singleThread){
        query.group = "perf"
    }

    const { error, result } = await get<{ total: number; messages: any[] }>('/messages/threads', { query });
    if (error) {
        return { error };
    }
    if (!result) {
        throw new Error('impossible');
    }

    const { total, messages } = result;

    const threads = {
        total,
        offset,
        items: messages.map(thread => transformThreadIn(thread))
    };

    return { result: threads };
}

//gets the slice of messages for a message that belongs to the thread, limited by offset and limit
// TODO: add limit
export async function getThread(messageId: number, type: 'email' | 'sms', offset = 0) {
    
    const { error, result } = await get<{ total: number; messages: any[] }>(`/messages/thread/${type}/${messageId}`, { query: { offset } });
    if (error) {
        return { error };
    }
    if (!result) {
        throw new Error('impossible');
    }

    const { total, messages } = result;

    return {
        result: {
            total,
            offset,
            items: messages.map(msg => transformMessageIn(msg))
        }
    };
}

export async function getConversation( performer: number, offset = 0, limit = 20): Promise<Response<Paged<Message>>>{
    const { error, result } = await get<{ total: number; messages:any[]}>(`/messages/thread/email/account/${performer}`, { query: { offset, limit } });
    console.log("Getting that chat thread");

    if (error) {
        return { error };
    }
    if (!result) {
        throw new Error('impossible');
    }
    const b = {
        "id": 2601205,
        "subject": "",
        "date": 1682326580,
        "content": "mooie radiator",
        "sent_by": "CLIENT",
        "billing_status": "FREE",
        "folder": "SENT",
        "read_status": "OLD",
        "replied_from": 0,
        "account_id": "12123",
        "username": " Tatiana ",
        "avatar": "e33ea0c66be8a5dcd8254600d750741702911144.jpg",
        "type": "email"
      };

    const { total, messages } = result;

      
    return {
        result: {
            total, offset, items: messages.map( msg => transformMessageIn(msg) )
        }
    }

    // const messages: Message[] = [
    //     {
    //         id: 1,
    //         subject: "Chat met Eliza",
    //         date: new Date(2024, 4, 12),
    //         content: "Hoi hoe is het?",
    //         billingStatus: 'FREE',
    //         folder: 'INBOX',
    //         sentBy: "CLIENT",
    //         readStatus: 'OLD',
    //         performerId: performer,
    //         type: 'email'
    //     },
    //     {
    //         id: 2,
    //         subject: "Chat met Eliza",
    //         date: new Date(2024, 4, 13),
    //         content: "Ik ben botergeil, wat jij?",
    //         sentBy: 'PERFORMER',
    //         billingStatus: 'PAID',
    //         folder: 'INBOX',
    //         readStatus: 'OLD',
    //         performerId: performer,
    //         type: 'email'
    //     },
    //     {
    //         id: 3,
    //         subject: "Chat met Eliza",
    //         date: new Date(2024, 4, 14),
    //         content: "Ik bak net pannekoeke.",
    //         sentBy: 'CLIENT',
    //         billingStatus: 'FREE',
    //         folder: 'INBOX',
    //         readStatus: 'OLD',
    //         performerId: performer,
    //         type: 'email'
    //     },
    //     {
    //         id: 4,
    //         subject: "Chat met Eliza",
    //         date: new Date(2024, 4, 15),
    //         content: "Ik houd meer van aubergines. Meerdere tegelijk liefst.",
    //         sentBy: 'PERFORMER',
    //         billingStatus: 'PAID',
    //         folder: 'INBOX',
    //         readStatus: 'OLD',
    //         performerId: performer,
    //         type: 'email'
    //     },
    //     {
    //         id: 5,
    //         subject: "Chat met Eliza",
    //         date: new Date(2024, 4, 16),
    //         content: "Ik ben meer een komkommer",
    //         sentBy: 'CLIENT',
    //         billingStatus: 'FREE',
    //         folder: 'INBOX',
    //         readStatus: 'OLD',
    //         performerId: performer,
    //         type: 'email'
    //     },
    //     {
    //         id: 6,
    //         subject: "Chat met Eliza",
    //         date: new Date(2024, 4, 16),
    //         content: "Ook wel kom kom maar geheten",
    //         sentBy: 'PERFORMER',
    //         billingStatus: 'NOT PAID',
    //         folder: 'INBOX',
    //         readStatus: 'OLD',
    //         performerId: performer,
    //         type: 'email'
    //     }
    // ]

    // return { result : { offset: 0, total: 6, items: messages.reverse() } };
}

//only gets the newest 100 messages in a thread. Should be sufficient though
export async function getMessagesAfter(messageId: number, type: 'email'|'sms' = 'email'){
    const { error, result } = await get<{ total: number, messages: any[] }>(`/messages/thread/update/${type}/${messageId}?limit=100`);
    if (error){
        return { error };
    }

    if (!result){
        throw new Error("impossible");
    }

    const { total, messages } = result;
    return {
        result: {
            total, 
            offset: 0,
            items: messages.map(msg => transformMessageIn(msg) )
        }
    }
}

// thuis_api_email_thread_add:
//     pattern: /api/messages/thread/message
//     defaults: {_controller: ThuisAccountBundle:EmailThread:add, _format:json}
//     methods: POST

//     {
//         "account_id": "150",
//         "content": "Als ik dit bericht verstuur, wat gebeurt er dan?",
//         "type": "email",
//         "reply_id": 1903475,
//         "subject": "Stuur es wat terug?"
//     }
export async function sendMessage(item: Partial<Message>): Promise<Response<Message>> {
    const { error, result } = await post('/messages/thread/message', {
        body: transformMessageOut(item)
    });

    if (error) {
        return { error };
    }

    if (!result) {
        throw new Error('impossible');
    }

    return { result: transformMessageIn(result) };
}



export async function deleteEmail(messageId: number): Promise<Response<Message>> {
    const { error, result } = await delEte(`/messages/thread/email/${messageId}`);

    if (error) {
        return { error };
    }

    if (!result) {
        throw new Error('impossible');
    }

    return { result: transformMessageIn(result) };
}

export async function payMessage(clientId: number, item: Partial<Message>) {
    const { error, result } = await post(`client/client_accounts/${clientId}/tax/performer_accounts/${item.performerId}`, {
        body: {
            serviceType: 'EMAIL',
            emailId: item.id
        }
    });

    if (error) {
        return { 
            error: {
                code: code(error.message), 
                message: error.message
            } 
        };
    }

    return { result: transformMessageIn(result) };
}

export async function sendMsg(body: { msg:string, receiver: number } ){
    return post(`session/chat_message`, { body });
}