import type { CategoryCard } from '@/ontology/category';
import { defineStore } from 'pinia';

type State = {
    values: CategoryCard[],
    selected?: string
}

export const useCategoriesStore = defineStore({
    id: 'categories',
    state: (): State => ({
        values: categories,
        selected: undefined
    }),
    actions: {
        getBySlug( name: string){
            return this.values.find( ({slug}) => slug == name )
        }
    }
});

const categories = [
    { 
        title: "Nieuw", 
        slug: "nieuw",
        performer: {
            id: 21570, advertNumber: 3240
        },
        image: {
            name: "ae2d24269b39456e5059f9cfe250fc1713356122.jpg",
            x: 0, y: -60, size: 100
        }
    },
    { 
        title: "Milf", 
        slug: "milfs",
        performer: {
            id: 13061, advertNumber: 3252
        },
        image: {
            name: "957d777f999d1182bb8effb9c34b811713346558.jpg",
            x: 0, y: -2, size: 100
        }
    },
    { 
        title: "Jong",
        slug: "jonge-vrouwen",
        performer: {
            id: 21301, advertNumber: 1914
        },
        image: {
            name: "0ee262eba9dfb66a27b56359da3eb41724680251.jpg",
            x: 0, y: 0, size: 100
        }
    },
    { 
        title: "Dikke tieten",
        slug: "dikke-tieten",
        performer: {
            id: 1285, advertNumber: 4655
        },
        image: {
            name: "1.jpg",
            x: -3, y: -97, size: 100
        }
    },
    { 
        title: "SM",
        slug: "sm",
        performer: {
            id: 17231, advertNumber: 4950
        },
        image: {
            name: "28caf46845220de24f7a8e8496b1141718721283.jpg",
            x: 0, y: 0, size: 100
        }
    
    },
    { 
        title: "BBW",
        slug: "bbw",
        performer: {
            id: 16284, advertNumber: 2028
        },
        image: {
            name: "e745b879968853ca98f70b294a51811714955057.jpg",
            x: 0, y: -110, size: 110
        }
    },
    { 
        title: "Slank",
        slug: "slanke-vrouwen",
        performer: {
            id: 7016, advertNumber:7816 
        },
        image: {
            name: "81ac9e40e63c3a57323a288126a40b1496431938.jpg",
            x: 0, y: -150, size: 125 
        }
    },
    { 
        title: "Zuid-Amerikaans",
        slug: "zuid-amerikaans",
        performer: {
            id: 18226, advertNumber: 8211
        },
        image: {
            name: "03a5948ff5593fe14555118b9cb1a81701115445.jpg",
            x: 0, y: 0, size: 100 
        }
    },
    { 
        title: "Toy",
        slug: "toy-dames",
        performer: {
            id: 13974, advertNumber: 8341
        },
        image: {
            name: "466034fe75553b103550642c8b25d61627994372.jpg",
            x: 0, y: -73, size: 100 
        }
    },
    { 
        title: "Bellen",
        slug: 'bel-dames',
        performer: {
            id: 20400, advertNumber: 8484
        },
        image: {
            name: "8e9137726b4a388e4182574394d1be1708610220.jpg",
            x: 0, y: -70, size: 100 
        }
    }
]
