import { clientSeen, initiateTeaser, switchMainTeaser, endSession } from "@/api/session";
import notifications from "@/socket";
import { bigBlackPoster, pickOne, seconds, subtract } from "@/utils";
import { defineStore } from "pinia";
import { usePerformerStore } from "./performer";
import { useUserStore } from "./user";
import { useTeaserVideoStore } from "./teaserVideo";
import { usePaymentStore } from "./payment";
import { useAlertsStore } from "./alerts";
import i18n from "@/translations";
import { getPerformerStatus } from "@/api/performer/utils";
import { watch, type WatchStopHandle } from "vue";
import type { Services } from "@/ontology/performer";

export type Status = 'idle' | 'choosing_payment' | 'checking' | 'active' | 'ending' | 'ended' ;

interface State {
    status: Status;
    current: {[id:number]:{
        type: "VOYEUR" | "VOYEURPEEK",
        position: number
    }},
    endReason:string;
    //url of an image that holds a fake image to stretch the tile
    posterUrl: string;
    history: number[],
    reservations: number[],
    aliveInterval: any,
    performerWatch?: WatchStopHandle

}
export const useTeaserStore = defineStore({
    id: 'teaser',
    state: ():State => ({
        status: 'idle',
        current: {},
        history: [],
        reservations: [],
        aliveInterval: undefined,
        endReason: '',
        posterUrl: '',
        performerWatch: undefined
    }),
    actions: {
        initialize(){
            //hmmm what push messages should I respond to?
            //42["receivedEvent","{\"event\":\"voyeur\",\"receiverId\":\"56121261\",\"receiverType\":\"ROLE_CLIENT\",\"content\":\"%7B%22performerId%22%3Anull%2C%22clientId%22%3A56121261%2C%22type%22%3A%22RESPONSE%22%2C%22value%22%3Afalse%2C%22id%22%3A%22777dc80efe4d0fe96b3d381700915ec3%22%2C%22message%22%3A%22BROKE%22%7D\"}"]
            //42["receivedEvent","{\"event\":\"voyeur\",\"receiverId\":\"56121261\",\"receiverType\":\"ROLE_CLIENT\",\"content\":\"%7B%22performerId%22%3A17359%2C%22clientId%22%3A56121261%2C%22type%22%3A%22RESPONSE%22%2C%22value%22%3Afalse%2C%22id%22%3A%22dba5ac6af7c6afa57c8e1c0f8d5c005c%22%2C%22message%22%3A%22MAIN_ENDED%22%7D\"}"]
            notifications.subscribe(
                'status_change',
                ( {sender, value, performer} )=>{
                    if (sender !== "teaserVideo"){
                        return;
                    }
                    if (value != "destroying"){
                        return;
                    }

                    //a performer was destroyed; let's replace her

                    this.history.push( performer );
                    this.replace( performer );
                }
            )
            notifications.subscribe(
                "voyeur",
                ( msg ) =>{
                    const { performerId, value, message } = msg;
                    if (performerId){
                        return;
                    }
                    if (value){
                        return;
                    }

                    this.ended( message );
                }
            )

            notifications.subscribe(
                "performer_available",
                ( { performer })=>{
                    if (!performer){
                        throw new Error("performer has to have a value yo");
                    }

                    const reservation = this.reservations.indexOf(performer);
                    if (reservation == -1){
                        return;
                    }

                    useAlertsStore().openMessage({
                        content: i18n.global.t("teaser.alerts.reservationAvailable", {performer: usePerformerStore().getById(performer).nickname }),
                        translate: false,
                        displayTime: seconds(3),
                        class: 'info'
                    })

                    this.reservations.splice(reservation, 1);
                }
            )

        },
        setStatus(newValue:Status){
            this.status = newValue;
            if (this.status == 'idle'){
                this.performerWatch && this.performerWatch();
                
                const performers = usePerformerStore();
                if (performers.currentSlice == 'voyeur'){
                    performers.currentSlice = undefined;
                }
            }

            notifications.sendLocalEvent('status_change', {
                sender: 'teaser',
                value: this.status
            });
        },
        async keepAlive() {
            if (this.status != 'active'){
                return;
            }

            const { error } = await clientSeen('VOYEUR');
            if (error && error.code == 401) {
                this.ended('OOPS');
            }
            //TODO: so what if another error that 401 is thrown?
        },
        initiate(withPerformer?:number){
            if (this.status != "idle"){
                return;
            }

            if (withPerformer){
                this.current[withPerformer] = { type: "VOYEUR", position:0 };

                const services = usePerformerStore().getById( withPerformer ).services;

                //when switching, the status will not fall through 'idle'
                //therefore, the watch will not be unwatched on reset.
                this.performerWatch = watch( services, this.performerChanged );
                
            }
            this.setStatus("choosing_payment");
        },
        //I'd like to have this in the payment store really..
        performerChanged({voyeur}:Services){
            if (this.status == 'choosing_payment' && voyeur.status != 'available'){
                this.setStatus('idle');
            }
        },
        async start(paymentType?:'credits' | 'ivr', ivrCode?:string){
            if (this.status != "choosing_payment"){
                return;
            }

            this.setStatus("checking");

            const payment = usePaymentStore();
            if (!paymentType) {
                ivrCode = payment.code;
            }

            payment.authorize('teaser', ivrCode ? 'ivr' : 'credits', ivrCode);

            const { error } = await initiateTeaser( useUserStore().account.id!, ivrCode );
            if ( error ){
                this.setStatus('choosing_payment');
                return error;
            }

            const performers = usePerformerStore();
            await performers.loadTeasers( { offset:0, limit: 1000 } );
            performers.currentSlice = "voyeur";
            this.setStatus("active");

            setInterval(this.keepAlive, seconds(5));

            //if there's no main performer yet, let's just pick one.
            let k = Object.keys(this.current).length;
            for(k; k<4; k++){
                const id = this.chooseNext();
                if (id > 0){
                    this.current[id] = { type: k ? "VOYEURPEEK" : "VOYEUR",  position: k }
                }
            }

        },
        chooseNext(stack=1):number{
            const available = usePerformerStore().getSlice("voyeur").items;
            const exclude = this.history.concat( Object.keys(this.current).map(id=>parseInt(id)) );
            let choices = subtract(exclude,  available);
            if (choices.length == 0){
                if (stack == 2){
                    return -1;
                } else {
                   //just reset the history and try again
                    this.history = [];
                    return this.chooseNext(stack+1)
                }
            } else {
                return pickOne( choices );
            }
        },
        replace(performer:number){
            const position = this.current[performer];
            if (!position){
                //scenario where message of the tease video being destroyed arrives after the status of teaser is already "idle"
                return;
            }

            delete this.current[performer];

            if (this.status != 'active'){
                return;
            }

            const next = this.chooseNext();
            if (!next){
                return;
            }

            this.current[next] = position;

        },
        switchTo(performer: number){
            switchMainTeaser(performer);

            if (!this.current[performer]){
                //OMG the performer disappeared while switching..
                return;
            }
            const oldId = this.getMainTeaser();
            if (oldId){
                this.current[oldId] = { type:"VOYEURPEEK", position: this.current[performer].position}
                useTeaserVideoStore( oldId ).type = 'VOYEURPEEK';
            }

            this.current[performer] = { type:"VOYEUR", position: 0 }
            useTeaserVideoStore( performer ).type = 'VOYEUR';
            
        },
        ended(reason: string){
            this.endReason = reason;
            this.setStatus("ended");
        },
        end(toIdle:boolean=false){
            //the user wants to end this whole teaser session
            if( ["ending", "ended"].includes(this.status) ){
                if (toIdle){
                    this.setStatus("idle");
                }
                return;
            }
            this.setStatus("ending");
            endSession({
                clientId: useUserStore().account.id!,
                type: "VOYEURCLIENT"
            });
            this.setStatus("ended");
            this.current = {};
            if (toIdle){
                this.setStatus("idle");
            }
        },
        toggleReservation(performer:number){
            const reservation = this.reservations.indexOf(performer);
            if (reservation > -1){
                this.reservations.splice( reservation, 1 );
            } else {
                useAlertsStore().openMessage({
                    content: i18n.global.t("teaser.alerts.reservationSuccess", {performer: usePerformerStore().getById(performer).nickname }),
                    translate: false,
                    displayTime: seconds(3),
                    class: 'success'
                });
                this.reservations.push( performer );
            }
        },
        isReserved(performer:number){
            return this.reservations.indexOf( performer ) > -1;
        },
        getMainTeaser(){
            for(let id in this.current){
                if (this.current[id].type == "VOYEUR"){
                    return parseInt(id);
                }
            }

            return undefined;
        }

    }

})