import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';

import * as Sentry from "@sentry/vue";

import App from './hc.vue';
import router from './router';

import './styles/main.scss';

import i18n from '@/translations';


import config from '@/config';
import { getConfirmation, getParameterByName, localStorageAvailable } from '../utils';
import { useLocalizationStore } from '@/stores/localization';
import { usePurchaseStore } from '@/stores/purchase';
import { useUserStore } from '@/stores/user';
import { usePerformerStore } from '@/stores/performer';
import { usePaymentStore } from '@/stores/payment';
import { useCamStore } from '@/stores/cam';
import { useChatStore } from '@/stores/chat';
import { useConversationStore } from '@/stores/conversation';
import { useToyStore } from '@/stores/toy';
import { useTeaserStore } from '@/stores/teaser';
import { useVideoCallStore } from '@/stores/videocall';
import { usePushStore } from '@/stores/push';
import { useMatomo } from '@/composables/useMatomo';
import { useDeviceStore } from '@/stores/device';
import notifications from '@/socket';

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);
app.use(i18n);

pinia.use( ({store})=> {
    store.router = markRaw(router);
})

function handleVisibilityChange() {
  
  if (document.visibilityState === 'hidden') {
      console.log("Page is inactive." + document.visibilityState);
      console.log(`en nu is de socket ${notifications.isConnected()} connected`);
      setInterval( ()=> console.log( `En NU is de socket ${notifications.isConnected()} connected` ), 1000)
  } else {
      console.log("Page is active." + document.visibilityState);
  }
}


// Event listener for visibility change
//document.addEventListener('visibilitychange', handleVisibilityChange);

if (!import.meta.env.DEV){
    Sentry.init({
        app,
        dsn: "https://d66aebc9b5c3d945a2d70cd42fb062a1@o228560.ingest.us.sentry.io/4506982797279232",
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false
          })
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
}

app.mount('#app');

startup();
//TODO: altijd in full screen openen
//document.body.requestFullscreen();

async function startup() {
    //initialize the analytics
    useMatomo().initMatomo();

    usePerformerStore().initialize();
    useCamStore().initialize();
    useVideoCallStore().initialize();
    usePaymentStore().initialize();
    useConversationStore().initialize();
    useChatStore().initialize();
    useToyStore().initialize();
    useTeaserStore().initialize();
    usePushStore().initialize();
    useDeviceStore().initialize();


    const userStore = useUserStore();
    userStore.init();

    // SafeMode
    const safeMode = getParameterByName('safe') === 'true';
    const gotsafe = safeMode ? userStore.toggleSafeMode() : '';
    
    // Mobile check
    const mobile = window.innerWidth < 768 ? userStore.toggleMobile() : '';

    //TODO cookie check etc..
    const storageName = `${config.StorageKey}.newFeatures`;
    if(!window.localStorage.hasOwnProperty(storageName)){
        window.localStorage.setItem(storageName, 'true');
    }

    // Age+cookie check
    const cookieageName = `${config.StorageKey}.cookies+agecheck`;
    if(!window.localStorage.hasOwnProperty(cookieageName)){
    }

    // Advertisement
    const fromAdvertiser = getParameterByName('utm_medium').toLowerCase() == 'advertising';
    let confirmation = getConfirmation();

    await userStore.authenticate(fromAdvertiser, confirmation);

    useLocalizationStore().initialize(userStore.account!.language!);
    usePurchaseStore().initialize();

}
