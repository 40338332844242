import { defineStore } from 'pinia';

type State = {
    list: {name: string, path:string}[]
}

export const useHistoryStore = defineStore({
    id: 'history',
    state: ():State => ({
        list: []
    }),
    getters: {
        current: state => state.list[0] || '',
        lastGrid: state => state.list.find( 
            ( {name} ) => [ "home" ].includes(name )
        ) || { name: 'home', path: '/' }
    },
    actions: {
        add(name:string, path:string) {
            this.list.unshift( {name, path} );
        }
    }
});
