import type { Role } from '@/ontology/user';
import { baseApi } from '.';
import type { Response } from './response';
import fetch from 'isomorphic-fetch';
import config from '@/config';

export interface ExtendedRequest extends RequestInit {
    query?: { [key: string]: any };
    body?: any;
}

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'HEAD' | 'DELETE' | 'PATCH' | 'OPTIONS';

const DEBUG = false;

export async function get<T>(path: string, options: ExtendedRequest = {}) {
    return ftsh<T>(path, options, 'GET');
}

export async function post<T>(path: string, options: ExtendedRequest = {}, role?: Role) {
    return ftsh<T>(path, options, 'POST', role);
}

export async function put<T>(path: string, options: ExtendedRequest = {}) {
    return ftsh<T>(path, options, 'PUT');
}

export async function delEte<T>(path: string, options: ExtendedRequest = {}) {
    return ftsh<T>(path, options, 'DELETE');
}

//wrapper function to do a fetch with json request/response, with credentials always included
export async function ftsh<T>(path: string, options: ExtendedRequest = {}, method: HttpMethod = 'GET', role?: Role): Promise<Response<T>> {
    options = {
        ...{ credentials: 'include', headers: new Headers(), method },
        ...options
    };

    if (config.Version) {
        (<Headers>options.headers).append('ver', config.Version);
    }

    //Translate a query param object to a query string to paste after the url
    if (options.query) {
        const query = Object.keys(options.query)
            .filter(k => options.query && options.query[k] !== undefined && options.query[k] !== '')
            .map(k => (options.query ? `${encodeURIComponent(k)}=${encodeURIComponent(options.query[k])}` : ''))
            .join('&');

        delete options.query;

        path = `${path}?${query}`;
    }

    //Automaticaly convert a body object to a stringified json and add the correct header
    if (options.body && typeof options.body === 'object') {
        (<Headers>options.headers).append('Content-Type', 'application/json');

        options.body = JSON.stringify(options.body);
    }

    //enable sending cookies received from prior requests.
    if (import.meta.env.MODE == 'test' && cookies) {
        (<Headers>options.headers).set('cookie', cookies);
    }

    if (role) {
        (<Headers>options.headers).append('role', role);
    }

    const url = `${baseApi}${!path.startsWith('/') ? '/' : ''}${path}`;

    if (DEBUG) {
        console.log('fetch url:', url);
        console.log('fetch options:', options);
    }

    const response = await fetch(url, options);

    //enable setting cookies in test context, to enable authenticated requests
    if (import.meta.env.MODE == 'test') {
        registerSetCookie(response);
    }

    if (!response.ok) {
        return {
            error: {
                code: response.status,
                message: guessMessage(await response.text()) || response.statusText
            }
        };
    }

    return {
        result: await response.json()
    };
}

export function clearCookies() {
    cookies = '';
}

let cookies = '';

function registerSetCookie(response: globalThis.Response) {
    const set = response.headers.get('set-cookie');

    if (set) {
        cookies = set;
    }
}

//
function guessMessage(response: string) {
    try {
        const obj = JSON.parse(response);
        const found = ['error', 'reason'].find(known => known in obj);
        if (found) {
            return obj[found];
        }

        //just return the first available property
        return Object.values(obj)[0];
    } catch (e) {
        return response;
    }
}
