import { createI18n } from 'vue-i18n';
import enMessages from './locales/en.json';
import deMessages from './locales/de.json';
import nlMessages from './locales/nl.json';
import config from '@/config';

const messages = {
    en: enMessages,
    de: deMessages,
    nl: nlMessages
};

const i18n = createI18n({
    locale: config.DefaultLanguage,
    fallbackLocale: 'nl',
    messages,
    fallbackWarn: false,
    missingWarn: false,
    silentTranslationWarn: true
});

export default i18n;
