import type { PurchaseOptions } from '@/ontology/purchaseOptions';
import { defineStore } from 'pinia';
import { updateBalanceInfo } from '@/api/client/client_accounts.updatebalanceinfo';
import { useUserStore } from './user';
import config from '@/config';

interface State extends PurchaseOptions {
    loading: boolean;
}

//let's see what else is needed here.. maybe some history?
export const usePurchaseStore = defineStore('purchase', {
    state: (): State => ({
        fees: [],
        packages: [],
        payment_methods: [],
        cart: [],
        promo: undefined,
        loading: false
    }),
    actions: {
        async initialize() {
            if (this.loading) {
                return;
            }
            this.loading = true;

            const { error, result } = await updateBalanceInfo();

            if (error) {
                //TODO: error handling
                return;
            }

            this.$patch({
                loading: false,
                ...result
            });

            this.setInitCart();
        },
        setInitCart() {
            const userId = useUserStore().account.id;
            if (window.localStorage.getItem(`${config.StorageKey}.payment-cache-${userId}`) === null) {
                this.cart = this.packages!.map(pack => ({
                    id: pack.id,
                    amount: 0,
                    quantity: pack.quantity,
                    price: pack.price
                }));
                return;
            }

            const paymentCacheString = window.localStorage.getItem(`${config.StorageKey}.payment-cache-${userId}`);
            const paymentCache = JSON.parse(paymentCacheString as any);
            this.cart = paymentCache.packages;
        },
        updateCart(id: number, amount: number) {
            const pack = this.cart!.find(obj => obj.id === id);
            pack!.amount = amount;

            this.storeCartCache();
        },
        storeCartCache() {
            const userId = useUserStore().account.id!;
            window.localStorage.setItem(
                `${config.StorageKey}.payment-cache-${userId}`,
                JSON.stringify({
                    packages: this.cart
                })
            );
        },
        clearCart() {
            this.cart = this.packages!.map(pack => ({
                id: pack.id,
                amount: 0,
                quantity: pack.quantity,
                price: pack.price
            }));

            const userId = useUserStore().account.id!;
            window.localStorage.removeItem(`${config.StorageKey}.payment-cache-${userId}`);
        },
        getCurrentAmount(id: number) {
            const current = this.cart!.find(obj => obj.id === id);
            return current ? current?.amount : 0;
        },
    },
    getters: {
        getBonus: state => {
            const credits = state.cart?.reduce((calc, obj) => {
                return calc + obj.quantity * obj.amount;
            }, 0);

            const feesIndex = state.fees.findIndex((fee: any) => credits! >= fee.amount);
            const applicableBonus = feesIndex === -1 ? 0 : state.fees[feesIndex].percentage;

            return Math.floor(credits! * (applicableBonus as any / 100));
        }
    }
});
