import { defineStore } from 'pinia';
import { annonConnect, checkSession, login, logout, register, update, updatePassword, remove, subscribe, unSubscribe, payContent, recoverPassword, resetPassword, confirmAccount } from '@/api/auth';
import { postMemo } from '@/api/performer';
import type { User, UserForm, UpdatePassword, Anonymous } from '@/ontology/user';
import { useLocalizationStore } from './localization';
import { useAlertsStore } from './alerts';
import { usePerformerStore } from './performer';
import config from '@/config';
import notifications, { connect } from '@/socket';
import i18n from "./../translations";


type AuthenticationStatus = 'idle' | 'authenticating' | 'initializing' | 'authenticated' | 'updating';
interface State {
    status: AuthenticationStatus;
    ageOK: boolean;
    cookiesOK: boolean;
    userMenu: boolean;
    catMenu: boolean;
    safeMode: boolean;
    mobile: boolean;
    confirmedType: string;
    account: Partial<User>;
    checkInterval?: any;
    //lists the resolve fuctions of the promises made to those who want to know when authentication is complete
    authResolvers: (() => void)[];
}

export interface PhotoPayload {
    serviceType: string;
    photoId: number;
}

export const useUserStore = defineStore('user', {
    state: (): State => ({
        status: 'idle',
        ageOK: false,
        cookiesOK: false,
        userMenu: false,
        catMenu: false,
        safeMode: false,
        mobile: false,
        confirmedType: '',
        account: {
            roles: ['ROLE_UNKNOWN'],
            username: 'anonymous'
        },
        checkInterval: undefined,
        authResolvers: []
    }),
    actions: {
        init(){
            notifications.subscribe('credits', this.creditsChanged )
            notifications.subscribe('email', this.newEmail);
        },
        async authenticate(visitFromPr0n: boolean, confirmation?:{ userId:number, token:string }) {
            this.setStatus('authenticating');
            //scenario's:
            // - someone's here for the first time referred by ponrhub-like people
            // - someone's here for the first time on his own initiative
            // - someone's here by clicking the confirmation link in the confirmation email
            // - someone's not for the first time here

            //TODO: load a prior visit from the local storage
            const stored = localStorage.getItem('user');
            if (stored) {
                const { cookiesOK = false, ageOK = false } = JSON.parse(stored || '{}');
                this.$patch({ cookiesOK, ageOK });
            }

            // if he is referred by a pr0n thing and we don't know him yet, use a default and otherwise do nothing
            if (visitFromPr0n && !stored) {
                (this.account = {
                    roles: ['ROLE_PR0N'],
                    username: 'anonymous'
                }),
                    this.setStatus('authenticated');

                return;
            }
            // if he's referred by a pr0n thing and his  local storate says we know him, just continue normally

            //if he tries confirming his account, do that first before going down the initialisation hassle
            if (confirmation){
                const { openMessage } = useAlertsStore();
                const { error } = await this.confirm(confirmation.userId, confirmation.token);
                if (error){
                    this.confirmedType = 'failed'

                    const known = [
                        'Account is already validated.',
                        'Expired token.',
                        'Invalid token.'
                    ]

                    //remove the '.' at the end to help with the translation..
                    const msg = known.includes( error.message ) ? error.message.slice(0,-1) : 'Invalid token';

                    openMessage({
                        content: `account.alerts.errorConfirmRegister${msg}`,
                        class: 'error'
                    });
                } else {
                    this.confirmedType = 'success'
                    openMessage({
                        content: 'account.alerts.successConfirmRegister',
                        class: 'success'
                    });
                }
                
            }

            const { error: errorCheck, result: checked } = await checkSession();

            //we have seen this user before
            if (checked) {
                this.account = checked;
                if (this.role() == 'ROLE_CLIENT'){
                    notifications.sendLocalEvent('authentication', {
                        type: 'loggedin'
                    });
                }
            }

            if (errorCheck && errorCheck.code != 403) {
                //something went very wrong, very unexpectedly
                //TODO:handle that error
                return; 
            }

            //we have not seen this user before!
            if (errorCheck && errorCheck.code == 403) {
                const { error: errorAnnon, result: annon } = await annonConnect(config.Country);

                if (errorAnnon) {
                    //TODO: handle that error
                    //somthing went very wrong, we did not expect that
                    return;
                }
                this.account = annon!;
                this.account.roles = ['ROLE_ANNON'];
            }

            this.initialize();
        },
        async confirm(userId:number, token:string){
            const { error: errorCheck } = await checkSession();

            if (errorCheck && errorCheck.code != 403) {
                //something went very wrong, very unexpectedly
                //TODO:handle that error
                return { error: errorCheck } 
            }

            //we have not seen this user before!
            if (errorCheck && errorCheck.code == 403) {
                const { error: errorAnnon, result: annon } = await annonConnect(config.Country);

                if (errorAnnon) {
                    //TODO: handle that error
                    //somthing went very wrong, we did not expect that
                    return { error: errorAnnon }
                }
                this.account = annon!;
                this.account!.roles = ['ROLE_ANNON'];
            }

            return await confirmAccount(userId, token);
        },

        annonToAccount(annon:Partial<User>):Partial<User>{
            return {
                ...annon,
                ...{
                    roles: ['ROLE_ANNON'],
                    username: i18n.global.t('account.anonymous')
                }
            }
        },

        //basically a function you can call if you need to wait until authentication is
        //complete. So you'd write 'await useUserStore().authentication()', which reads nicely. Admit it.
        async authentication() {
            if (this.status == 'authenticated') {
                return;
            }
            return new Promise<void>(resolve => this.authResolvers.push(resolve));
        },
        async recoverPassword(email: string) {
            const { error, result } = await recoverPassword(email);

            if (error) {
                return;
            }
        },
        async resetPassword(userId: number, password: string, token: string) {
            const { error, result } = await resetPassword(userId, password, token);
            
            if(error) {
                return error;
            }
        },
        async login(email: string, password: string, token: string) {
            if(navigator.webdriver) {
                return;
            }

            await this.ensureAtLeastAnnonymousAccount();
            
            if (!notifications.isConnected()){
                await connect(config.SocketUrl, {
                    id: this.account.id!,
                    token: this.account.socketToken!,
                    type: 'ROLE_CLIENT'
                });
            }

            this.setStatus('authenticating');
            await checkSession(); // Cookiefix

            const { error, result } = await login(email, password, token, 'ROLE_CLIENT');
            if (error) {
                //login failed; account is not changed
                this.setStatus('authenticated');
                return error;
            }

            this.account = result!;
            notifications.sendLocalEvent('authentication', {
                type: 'loggedin'
            });

            this.initialize();
        },
        async register(user: UserForm) {
            //Set both values from localize store
            const local = useLocalizationStore();
            user.country = local.country;
            user.language = local.language;
            user.passwordconfirm = '';

            const { error } = await register(user);
            if (error) {
                //implement global error message
                return error;
            }
        },

        async ensureAtLeastAnnonymousAccount(){
            if (!this.account.roles?.includes('ROLE_PR0N')){
                return;
            }

            const { error, result } = await annonConnect(config.Country);

            if (error) {
                //TODO: handle that error
                //somthing went very wrong, we did not expect that
                return { error };
            }
            this.account = this.annonToAccount(result!);
        },

        async logout() {
            if (this.account && this.account.roles![0] != 'ROLE_CLIENT') {
                //loggin out while not logged in makes no sense
                return;
            }

            const { error } = await logout();
            if (error) {
                return;
            }

            this.setStatus('authenticating');
            //TODO: let's use the country the logged out user was using
            const { error: errorAnnon, result: annon } = await annonConnect(config.Country);

            if (errorAnnon) {
                //somthing went very wrong, we did not expect that
                return;
            }

            //TODO: remove all user specific data from the store. Like e.g. favorites

            this.account = annon!;
            //I'm setting this role already, otherwise it won't be set until the next check_session
            this.account!.roles = ['ROLE_ANNON'];
            notifications.sendLocalEvent('authentication', {
                type: 'loggedout'
            });
            this.initialize();
        },
        async update(user: User) {
            this.setStatus('updating');
            const { error, result } = await update(user);

            if (error) {
                // error updating user
                return;
            }

            this.account = result!;
            this.setStatus('authenticated');
        },
        async updatePassword(password: UpdatePassword, userId: number) {
            this.setStatus('updating');
            const { error, result } = await updatePassword(password, userId);

            if (error) {
                // error updating password
                return error;
            }

            this.setStatus('authenticated');
        },
        async remove(reason: Object, userId: number){
            this.setStatus('updating');
            const { error, result } = await postMemo({ 
                content: JSON.stringify(reason), client: { id: userId } 
            });

            if (error) {
                return;
            }

            const { error: error1, result: result1 } = await remove(userId);

            if (error1) {
                return;
            }

            this.setStatus('idle');
            this.logout();
        },
        async toggleSubscribe(userId: number, performerId: number) {
            this.setStatus('updating');
            const { selected, getById } = usePerformerStore();
            if (!selected){
                return;
            }
            const selectedPerformer = getById( selected );
            const { error, result } = selectedPerformer?.isSubscribed ? await unSubscribe(userId, performerId) : await subscribe(userId, performerId);

            if (error) {
                // error updating user
                return;
            }

            this.account = result!;
            this.setStatus('authenticated');
        },
        async payContent(userId: number, performerId: number, payload: PhotoPayload) {
            const { error, result } = await payContent(userId, performerId, payload);

            if (error) {
                // error updating user
                return error;
            }
        },
        async updateUserNotification(user: User, notify?: string) {
            this.setStatus('updating');

            if(notify){
                user.notificationTypes = user.notificationTypes ? user.notificationTypes : {online: false, promotion: false, message: false};
                user.notificationTypes[notify] = user.notificationTypes[notify] ? false : true;
            }

            const { error, result } = await update(user);

            if(error){
                // error updating user
                return;
            }

            this.account = result!;
            this.setStatus('authenticated');
        },
        //connects to the websocket and starts the checksession dance
        async initialize() {
            if (this.status != 'authenticating') {
                return;
            }

            //TODO: not awaiting anything between initializing and authenticated makes the intiializing status redundant
            this.setStatus('initializing');

            //connect to the notification server
            const { id, socketToken } = this.account!;
            connect(config.SocketUrl, {
                id: id!,
                token: socketToken!,
                type: 'ROLE_CLIENT'
            });

            await notifications.connection();

            //start the checksession dance
            this.startSessionCheck();
            this.setStatus('authenticated');
        },
        startSessionCheck() {
            const oneMinute = 60 * 1000;
            if (this.checkInterval) {
                clearInterval(this.checkInterval);
            }

            this.checkInterval = setInterval(async () => {
                const { error, result } = await checkSession(false);

                //just in case someone is loggin in while check session is executing
                if (result && this.status == 'authenticated') {
                    result.creditsUntil = this.account.creditsUntil || 0;
                    this.account = result;
                }
                if (error) {
                    if (error.code == 403 && this.role() == 'ROLE_CLIENT') {
                        this.logout();
                    } else {
                        //TODO: handle this very unexpected error
                    }
                }
            }, oneMinute);
        },
        creditsChanged( update: {credits:number, creditsUntil: number, serverTime:number|string }){
            const {credits, creditsUntil } = update;
            const serverTime = (typeof update.serverTime == 'string') ? parseInt(update.serverTime) : update.serverTime;

            this.account.credits = credits;
            if (creditsUntil && ( creditsUntil > serverTime) ){
                this.account.creditsUntil = creditsUntil-serverTime + Math.floor(( Date.now() / 1000 ));
            } else {
                this.account.creditsUntil = undefined;
            }
        },
        newEmail(){
            if (!this.account) return;
            this.account.totalNotifications = (this.account.totalNotifications || 0 )+1;
        },
        setStatus(value: AuthenticationStatus) {
            if (value == this.status) {
                return;
            }

            this.status = value;

            if (value == 'authenticated') {
                this.authResolvers.forEach(resolve => {
                    resolve();
                });
                this.authResolvers = [];
            }
        },
        role() {
            if (this.account?.roles?.length) {
                return this.account.roles[0];
            } else {
                return 'ROLE_UNKNOWN';
            }
        },
        isLoggedIn() {
            return this.role() === 'ROLE_CLIENT';
        },
        toggleSafeMode() {
            this.safeMode = !this.safeMode;
        },
        toggleMobile() {
            this.mobile = !this.mobile;
        },
        toggleUserMenu() {
            this.catMenu = false;
            this.userMenu = !this.userMenu;
        },
        toggleCatMenu() {
            this.userMenu = false;
            this.catMenu = !this.catMenu;
        }
    }
});
