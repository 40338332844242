import { useMatomo } from '@/composables/useMatomo';
import notifications from '@/socket';
import { isIncognitoGuess, localStorageAvailable, seconds } from '@/utils';
import { defineStore } from 'pinia';
import * as platform from 'platform';
import { useUserStore } from './user';
import config from '@/config';

type InstallationStatus = 'unknown' | 'not-installed' | 'waited-long-enough' | 'not-installable' | 'installable' | 'installing' | 'installed' | 'freshly-installed';

type State = {
    isPWA: boolean, 
    storage: Storage, 
    os?: string,
    installPrompt: any,
    intv: any,
    installationStatus: InstallationStatus,
    counter: number,
    loaded: ('beforeinstall' | 'relatedapps' | 'serviceworker')[]
}

const isPWA = window.matchMedia('(display-mode: standalone)').matches;

export const useDeviceStore =  defineStore({
    id: 'device',
    state: (): State => ({
        isPWA, 
        storage:  localStorageAvailable()? window.localStorage : window.sessionStorage,
        os: platform.os?.family,
        installPrompt: undefined,
        installationStatus: 'unknown',
        counter: 10,
        intv: undefined,
        loaded: []
    }),
    actions: {
        async initialize(){
            if ( 'onbeforeinstallprompt' in window){
                window.addEventListener('beforeinstallprompt', (event)=>{
                    this.loaded.push("beforeinstall");
                    console.dir( this.loaded.slice(0));
                    this.setInstallationStatus("installable");
                    
                    this.installPrompt =  event;
                });
            } else {
                console.log("onbeforeinstall not available");
            }

            if ('getInstalledRelatedApps' in navigator ){
                const related: {id: string}[] = await (navigator.getInstalledRelatedApps as any)();
                this.loaded.push( "relatedapps" );
                console.dir( this.loaded.slice(0));
                console.dir(related);
                if ( related.length ){
                    this.setInstallationStatus("installed")
                } else {
                    this.setInstallationStatus("not-installed");
                }
            } else {
                console.log( "getInstalledRelatedApps not available" );
            }

            notifications.subscribe("authentication", this.authenticated );

        },
        authenticated(parameters: any) {
            const { type } = parameters;
            if (type !== 'loggedin') return;

            const id = useUserStore().account!.id

            //when running as pwa for the first time, let matomo know            
            if (  !this.storage.getItem("installed") ){
                useMatomo().trackPageView(`/installed/?u=${id}`, 'installed');

                this.storage.setItem( "installed", config.Version );
            }
            console.log("track that user");
            useMatomo().trackUser( id );
        },
        async registerServiceWorker(){
            //register the service worker for the app
            if ('serviceWorker' in navigator) {
                try{
                    await navigator.serviceWorker.register( '/sw.js');
                    this.loaded.push( "serviceworker" );
                    console.dir( this.loaded.slice(0));
                    this.incognitoCheck();
                } catch( e ){
                    console.log('laden service worker mislukt');
                }
            }
            
        },
        setInstallationStatus(toValue:InstallationStatus){
            const allowed = {
                'unknown': ['not-installed', 'installable', 'installed'],
                'not-installed': ['installable', 'not-installable', 'waited-long-enough'],
                'waited-long-enough': [ 'installable' ],
                'installable': ['installing', 'installed'],
                'installing': ['freshly-installed'],
                'freshly-installed': [] as string[],
                'not-installable': ['installable'],
                'installed': [] as string[]
            }


            const to = allowed[ this.installationStatus ];
            console.log( `transisitie van ${this.installationStatus} naar ${toValue}` );
            if (to.includes( toValue )){
                if (this.installationStatus == 'not-installed'){
                    this.stopCounter();
                }
                this.installationStatus = toValue;

                if (toValue == 'installing'){
                    this.pollInstallation();
                }

                if(toValue == 'not-installed'){
                    this.startCounter();
                    this.incognitoCheck();
                }

                if (toValue == 'waited-long-enough'){
                    useMatomo().trackEvent("installation", "countdown" );
                }
                if (toValue == 'not-installable'){
                    useMatomo().trackEvent("installation", "private-mode" );
                }

            }

        },

        pollInstallation(){
            let counter = 0;
            let interval = setInterval( async ()=>{
                counter++;
                //just stop if we already tried 100 times
                if (counter > 100){
                    clearInterval(interval);
                    return;
                }

                if (!('getInstalledRelatedApps' in navigator ) ){
                    clearInterval(interval);
                    return;
                }    
                
                const related: {id: string}[] = await (navigator.getInstalledRelatedApps as any)();
                if (!related.length){
                    return;
                }

                clearInterval(interval);
                this.setInstallationStatus("freshly-installed");

            }, seconds(1) )
        },
        async incognitoCheck(){
            //wait for the service worker to load 
            if (!this.loaded.includes("serviceworker")){
                return;
            }

            if (this.installationStatus != 'not-installed'){
                return;
            }

            setTimeout( async ()=>{
                if (this.installationStatus != 'not-installed'){
                    return;
                }
                if (await isIncognitoGuess()){
                    this.setInstallationStatus('not-installable');
                }
            }, seconds(1) )
        },
        startCounter(){
            this.intv = setInterval( () => {
                this.counter--;
                if (this.counter == 0){
                    this.stopCounter();
                    this.setInstallationStatus('waited-long-enough');
                }
            }, seconds(1) )
        },
        stopCounter(){
            clearInterval( this.intv );
        }

    

    }
})
