<template>
    <div v-if="active" class="clone" :style="styles">
        <canvas ref="canvas"/>
    </div>
</template>

<script lang="ts" setup>
    import notifications from '@/socket';
    import { sleep } from '@/utils';
    import { computed, ref, onBeforeMount, type Ref, nextTick } from 'vue';

    const active = ref( false );
    const bounds = ref( { top:0, left:0, width:0, height:0, borderRadius: "0px" } );
    const canvas:Ref<HTMLCanvasElement | null> = ref(null);

    let moveTarget:string; //id of the element to move to
        
    async function clone(id:string, how?:string){
        const original = document.getElementById(id);
        if (!original){
            throw `Dude ${id} is not even in the DOM tree!`;
        }
        
        const { top, left, width, height } = original.getBoundingClientRect();

        const styles = getComputedStyle(original);
        const borderRadius = styles.getPropertyValue("border-radius");
        bounds.value = { top, left, width, height, borderRadius } 

        //let's copy the image if it's an image element we just clicked
        active.value = true;
        //sniff sniff?
        await nextTick();
        const c = canvas.value;
        if (!c){
            console.log("hold on, there's no canvas yet??");
        } else {
            const img = original as HTMLImageElement;
            c.width = Math.min(img.naturalWidth, 600) ;
            c.height = Math.min(img.naturalHeight, 800);
            c.getContext("2d")!.drawImage( img, 0, 0, c.width, c.height );
        }

    }

    async function moveTo(id?:string){
        const destination = document.getElementById(id || moveTarget);
        if (!destination){
            //pretend the move is done, cause apparently there is nothing to move to  ¯\_(ツ)_/¯
            notifications.sendLocalEvent("morph", { event: "moved"} );
            active.value=false;
            return;
        }

        const { top, left, width, height } = destination.getBoundingClientRect();
        const styles = getComputedStyle(destination);
        const borderRadius = styles.getPropertyValue("border-radius");
        bounds.value = { top, left, width, height, borderRadius } 
        
        moveTarget = "";

        await sleep(300);
        notifications.sendLocalEvent("morph", { event: "moved"} );
    }

    onBeforeMount( ()=>{
        
        notifications.subscribe( "morph", ( notification:any )=>{
            //console.log("NOTIFICATION!!" + JSON.stringify( notification ) );
            switch( notification.event ){
                case "clone":
                    clone( notification.element )
                    break;
                case "prepareMove":
                    moveTarget = notification.element;
                    break;
                case "moveTo":
                    moveTo( notification.element );
                    break;

                case "ready2go":    
                    //the morph must go           
                    active.value = false;
                    break;

                case "moved":
                    break;
                default:
                    throw new Error(`Duide! Handle ${ JSON.stringify( notification ) }`);
            }
        })
    } ) 

    const styles = computed( ()=>{
        const {top, left, width, height, borderRadius } = bounds.value;
        return {
            top:`${top}px`, left:`${left}px`, width: `${width}px`, height:`${height}px`, borderRadius
        }
    })
    
</script>

<style lang="scss" scoped>
    .clone{
        position: fixed;
        box-sizing: border-box;
        pointer-events: none;
        transition-property: all;
        transition-duration: 300ms;
        transition-timing-function: cubic-bezier(0.23, 1, 0.320, 1);
        overflow:hidden;

        canvas{
            width: 100%;
            height: 100%;
            position:absolute;
        }
    }

</style>