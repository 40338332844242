<!--Ad Visser-->
<script lang="ts" setup>
    import { defineAsyncComponent, onBeforeMount, onBeforeUnmount, ref, shallowRef, type Ref } from 'vue';
    import { usePerformerStore } from '@/stores/performer';
    import type { EmailNotification } from '@/ontology/notifications';
    import notifications from '@/socket';
    import { useRouter } from 'vue-router';
    import { seconds } from '@/utils';
import { usePushStore } from '@/stores/push';

    const router = useRouter();
    const expanded = ref(false);
    const transformation = ref("");
    const root:Ref<null | HTMLDivElement> = ref(null);
    const currentPop: Ref<{ component: any, props: any }> = ref( {
        component: null, props: undefined
    } )

    let touchStartedAt: number = -1;
    let touchId:number | undefined;
    let originalHeight: number = -1;
    let subscriptionIds:number[] = [];
    let timeout: any;

    onBeforeMount( async ()=>{
        subscriptionIds = [ 
            notifications.subscribe( "email",  handleEmailNotification ),
            notifications.subscribe( "performer_available",  handleAvailableNotification ) 
        ];
    })   

    onBeforeUnmount( ()=>{
        subscriptionIds.forEach( id => notifications.unsubscribe(id) );
    })

    function showPop(which:string, toInject: any){
        const known = ["NewMessage"];//PerformerOnline, PerformerAvailable
        if (!known.includes(which) ){
            throw `${which} ain't no pop I ever heard of. Choose from: ${known.join(",")}`;
        }

        currentPop.value = { 
            component:  shallowRef( defineAsyncComponent( () => import(`./popins/${which}.vue`) ) ),
            props: toInject
        }
        expanded.value = true;
        
        timeout = setTimeout( hidePop, seconds(5) );
    }

    function hidePop(){
        expanded.value = false;
        clearTimeout( timeout );
    }

    async function handleEmailNotification( { performerId }: EmailNotification ) {
        const performers = usePerformerStore();

        let p = performers.getById( performerId );
        if (!p){
            p = await performers.loadPerformerById( performerId );
        }

        if ( router.currentRoute.value.params.current as string == p.advertNumber.toString() ){
            return;
        }

        showPop("NewMessage", { performer: p, type: "newMessage" } );
    }

    async function handleAvailableNotification( notification: { performer:number } ){
        const notifications = usePushStore();
        if ( !notifications.isSubscribed( notification.performer )){
            return;
        }

        const performers = usePerformerStore();

        let p = performers.getById( notification.performer );
        if (!p){
            p = await performers.loadPerformerById( notification.performer );
        }

        if ( router.currentRoute.value.params.current as string == p.advertNumber.toString() ){
            return;
        }

        showPop("NewMessage", { performer: p, type: "available" })
    }


    function touchStart(event:TouchEvent){
        if (event.touches.length != 1){
            return;
        }

        if (!root.value){
            throw "Where my root at misstah??"
        }

        originalHeight = root.value.getBoundingClientRect().height;
        touchId = event.touches[0].identifier;
        touchStartedAt = event.touches[0].screenY;
    }

    function touchMove(event: TouchEvent){
        if (!touchId){
            return;
        }

        const touch = Array.from( event.touches ).find( ({identifier})=>identifier == touchId );
        if (!touch){
            return;
        }

        event.preventDefault();

        const change = touch.screenY - touchStartedAt;
        if (change > 0){
            //laat de historie van meldingen zien
            return "";
        }

        transformation.value = `transform: translateY(${change}px);transition-property:none;`;
    }

    function touchEnd(event:TouchEvent){
        const touch = Array.from( event.changedTouches ).find( ({ identifier }) => identifier == touchId)
        if (!touch){
            return;
        }
        
        //to dismiss or not dismiss?
        touchId = undefined;
        transformation.value = "";
        if ( touch.screenY < (originalHeight / 2) ){
            hidePop();
        } 
        
    }

</script>

<template>
    <div ref="root" class="top-popin" :class="{ expanded }" :style="transformation" @touchstart="touchStart" @touchmove="touchMove" @touchend="touchEnd"> 
        <component :is="currentPop.component" v-if="currentPop.component" v-bind="currentPop.props" @click="expanded=false"></component>
    </div>
</template>

<style lang="scss" scoped>
    .top-popin{
        background: black;
        border-radius: 0 0 35px 35px;
        position: fixed;
        left: 0;
        width: 100vw;
        top:0;

        transition-property: all;
        transition-duration: 500ms;
        padding: 1rem;
        padding-top: 0;
        transform: translateY(-100%);
    }

    .expanded{
        transform: translateY(0);
    }

</style>