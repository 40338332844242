<script setup lang="ts">
    import { computed, watch } from 'vue';
    import { useRoute, type RouteRecordName } from 'vue-router';

    import home from "@/hollandcams/components/icons/BottomMenu/home.vue";
    import search from "@/hollandcams/components/icons/BottomMenu/search.vue";
    import favorites from "@/hollandcams/components/icons/BottomMenu/favorites.vue";
    import chats from "@/hollandcams/components/icons/BottomMenu/chats.vue";
    import account from "@/hollandcams/components/icons/BottomMenu/account.vue";

    const route = useRoute();

    const menuRoutes = { home, search, favorites, chats, account };
    const subRoutes = ["search-results", "category"];
    const menuVisible = computed( ()=>{
        return Object.keys(menuRoutes).concat(subRoutes).includes( route.name as string );
    })

    function same(routeName:RouteRecordName, menuItem:string ){
        if (menuItem == "search"){
            return ["search-results", "category", "search"].includes( routeName.toString())
        }

        return routeName == menuItem;
    }

</script>

<template>
    <menu v-if="menuVisible">
        <li v-for="[name, icon] in Object.entries(menuRoutes)" :key="name">
            <router-link :to="{ name }" ><component :is="icon" :color="same(route.name!, name) ? '#FFF': '#8E8E8E' "></component></router-link>
        </li>
    </menu>
</template>

<style scoped lang="scss">
    menu{
        display: flex;
        position: fixed;
        bottom: 0;
        height: 4rem;
        background-color: black;
        width: 100%;
        padding-bottom: 1rem;
        box-sizing: content-box;

        justify-content: space-evenly;
        align-items: center;

        li{
            text-align: center;
            font-weight: 600;
        }

        svg{
            height: 30px;
        }
    }
</style>