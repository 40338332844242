import type { Performer } from '@/ontology/performer';
import type { User } from '@/ontology/user';
import { delEte, get, post } from '../fetch-extension';
import { transformPerformers, type Filter, type Performers } from '../performer/utils';
import type { Response, Paged } from '../response';

//loads a paged view of the favorites of a client
//https://www.thuis.nl/api/client/client_accounts/56121261/favorite_performers?limit=40&offset=0
export async function getFavorites(forClient: number, query: { offset: number; limit: number }): Promise<Response<Paged<Performer>>> {
    const response = await get<Performers>(`client/client_accounts/${forClient}/favorite_performers`, { query });
    return transformPerformers(response, query.offset);
}

//adds a performer as a favorite for a client
//POST https://www.thuis.nl/api/client/client_accounts/56121261/favorite_performers/14553
export async function addFavorite(performerId: number, forClient: number) {
    return post<User>(`client/client_accounts/${forClient}/favorite_performers/${performerId}`);
}

//removes a performer as a favorite for a client
//DELETE https://www.thuis.nl/api/client/client_accounts/56121261/favorite_performers/14553
export async function removeFavorite(performerId: number, forClient: number) {
    return delEte<User>(`client/client_accounts/${forClient}/favorite_performers/${performerId}`);
}
