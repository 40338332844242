import notifications from "@/socket";
import { defineStore } from "pinia";
import { useDeviceStore } from "./device";

interface State {
    subscriptions: {
        id: number
    }[]
}

// Keeps track of performers the user wants to get a push notification for when she becomes available.
// 
export const usePushStore = defineStore({
    id: 'pushes',
    state: (): State => ({
        subscriptions: []
    }),
    actions: {
        initialize(){
            const data = useDeviceStore().storage.getItem("subscriptions");
            if (!data){
                return;
            }
            this.subscriptions = JSON.parse( data );            

        },
        isSubscribed( performerId:number ){
            return this.subscriptions.findIndex( ({id})=> id == performerId ) > -1;
        },
        add( performerId:number ){
            if (this.isSubscribed( performerId )){
                return;
            }

            this.subscriptions.push( { id: performerId } );
            useDeviceStore().storage.setItem("subscriptions", JSON.stringify( this.subscriptions ) );
        },
        remove( performerId:number ): boolean{
            const ix = this.subscriptions.findIndex( ({id})=> id == performerId );
            if (ix == -1){
                return false;
            }

            this.subscriptions.splice( ix, 1 );
            useDeviceStore().storage.setItem("subscriptions", JSON.stringify( this.subscriptions ) );
            return true;
        }
    }
});