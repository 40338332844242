import type { Thread, Message } from '@/ontology/messaging';
import notifications from '@/socket';
import { kebabToCamel } from '@/utils';
import type { Paged } from '../response';

const ApiFOrmat = {
    id: 2346570,
    subject: 'bijladen',
    date: 1663877952,
    msgcount: 392,
    folder: 'INBOX,SENT',
    read_status: 'OLD',
    account_id: '12231',
    username: 'Celeste',
    type: 'email'
};

export function transformThreadIn(fromApi: any): Thread {
    const result: Partial<Thread> = {};
    for (var prop in fromApi) {
        switch (prop) {
            case 'date':
                result.date = new Date(fromApi.date * 1000);
                break;
            case 'msgcount':
                result.totalMessages = fromApi.msgcount;
                break;
            case 'folder':
                result.folders = fromApi.folder.split(',');
                break;
            case 'read_status':
                result.readStatus = fromApi.read_status == "OLD" ? "OLD" : "NEW";
                break;
            case 'account_id':
                result.performerId = parseInt(fromApi.account_id);
                break;

            default:
                (result as any)[prop] = fromApi[prop];
        }
    }

    return result as Thread;
}

export function transformMessageIn(fromApi: any): Message {
    const result: Partial<Message> = {};
    for (var prop in fromApi) {
        switch (prop) {
            case 'date':
                result.date = new Date(fromApi.date * 1000);
                break;
            case 'sent_by':
            case 'billing_status':
            case 'read_status':
            case 'replied_from':
                (result as any)[kebabToCamel(prop)] = fromApi[prop];
                break;
            case 'account_id':
                result.performerId = parseInt(fromApi.account_id);
            case 'username':
                break;
            default:
                (result as any)[prop] = fromApi[prop];
        }
    }
    return result as Message;
}

export function transformMessageOut(toApi: Partial<Message>): any {
    const result: any = {};
    for (var prop in toApi) {
        switch (prop) {
            case 'repliedFrom':
                result.reply_id = toApi.repliedFrom;
                break;
            case 'performerId':
                result.account_id = toApi.performerId;
                break;
            default:
                result[prop] = (toApi as any)[prop];
        }
    }
    return result;
}

export function prepend( messages: Paged<Message>, before: Paged<Message> ): Paged<Message>{
    //make sure none of the messages are already in into
    const toAdd = messages.items.filter( ({id})=> !before.items.find( ({id:other})=> other == id) );

    //make sure the sort order is descending by id
    const merged = toAdd.concat( before.items );//.sort( ( one, other )=>other.id - one.id );

    return {
        offset: messages.offset,
        total: messages.total + before.total, //update the total
        items: merged
    }
}

export function append( messages: Paged<Message>, after: Paged<Message> ): Paged<Message>{
    //make sure none of the messages are already in into
    const toAdd = messages.items.filter( ({id})=> !after.items.find( ({id:other})=> other == id) );

    //make sure the sort order is descending by id
    const merged = after.items.concat(toAdd);

    return {
        offset: messages.offset,
        total: messages.total,
        items: merged
    }
}

export function sendTypingMessage(toPerformer: number, fromClient: number) {
    notifications.sendCustomEvent('event', {
        event: 'typing_received',
        receiverId: toPerformer,
        receiverType: 'ROLE_PERFORMER',
        content: encodeURIComponent(JSON.stringify({
            recentTyping:true, 
            inBuffer:true, 
            senderType: "ROLE_CLIENT",
            senderId:fromClient
        }))
    });
}

export function content(message:Message){
    if (!message.contentTranslate){
        return message.content;
    } else if ( message.translate ){
        return message.content;
    } else {
        return  message.contentTranslate;
    }
}